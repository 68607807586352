.page {
    width: calc(100% - 80px);
    /* Adjust the width to leave space for the navbar */
    height: 100%;
    position: absolute;
    overflow: auto;
    /* Add vertical and horizontal scrollbars */
    left: 60px;
    /* Adjust the left value according to your navbar width */
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.container.contact-page,
.container.about-page {

    h1 {
        font-size: 51px;
        font-family: 'Coolvetica';
        color: #CCA300;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 10px;
        left: 0px;
    }

    p {
        font-size: 15px;
        color: #fff;
        font-family: sans-serif;
        font-weight: 300;
        max-width: fit-content;
        animation: pulse 1s;

        &:nth-of-type(1) {
            animation-delay: 1.1s;
        }

        &:nth-of-type(2) {
            animation-delay: 1.2s;
        }

        &:nth-of-type(3) {
            animation-delay: 1.3s;
        }
    }

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
    }

    .text-animate-hover {
        &:hover {
            color: #F5F5DC;
        }
    }
}